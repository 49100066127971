import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TreeNode } from 'enel-tree/dist/enel-tree/lib/models';
import { MatIconRegistry } from '@angular/material/icon';
import { NodeDefinition } from 'enel-tree/dist/enel-tree';
import { Subscription } from 'rxjs';
import { Site } from '@model';
import { SiteService, SidenavService } from '@service';

@Component({
  selector: 'premo-tree',
  templateUrl: './premo-tree.component.html',
  styleUrls: ['./premo-tree.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'),
      ),
    ]),
  ],
})
export class PremoTreeComponent implements OnDestroy, OnInit {
  loading = true;

  subscribers: Subscription[] = [];
  clicked: TreeNode<Site>;
  expandedNodes: Set<TreeNode<Site>> = new Set<TreeNode<Site>>();
  selectedNodes: Set<TreeNode<Site>> = new Set<TreeNode<Site>>();
  sites: Site[] = [];
  treeData: TreeNode<Site>[] = [];
  selection: TreeNode<Site>[] = [];
  shownExpandedNodes: TreeNode<Site>[] = [];
  clickedNode: TreeNode<Site>;
  checkbox = false;
  expandable = true;
  search = true;
  selectAllResults = false;
  allowDeselectAll = false;
  maxSelections = 1;
  labelIdentifier = 'displayLabel';
  iconIdentifier = 'spaceType';
  SITE_SPACE_TYPE = 'Site';
  DEVICE_SPACE_TYPE = 'Device';
  iconDefinitions: { [key: string]: string } = {
    PEOPLE_ALT: 'https://cdn.dev-apps.enelx.net/img/icons/people_alt.svg',
    FOLDER_OPEN_OUTLINED:
      'https://cdn.dev-apps.enelx.net/img/icons/folder_open_outlined.svg',
    FACTORY: 'https://cdn.dev-apps.enelx.net/img/icons/factory.svg',
    ENELX: 'https://cdn.dev-apps.enelx.net/img/icons/enelx.svg',
  };
  iconMap: { [key: string]: string } = {
    Device: 'FOLDER_OPEN_OUTLINED',
    Site: 'FACTORY',
  };
  iconKeys: string[] = [
    'FOLDER_OPEN_OUTLINED',
    'FACTORY',
  ];
  disabledNodeAttribute = 'spaceType';
  disabledNodeValues: string[] = [];
  searchProperties: string[] = ['displayLabel'];
  disabledNodeDefinitions: NodeDefinition = {
    [this.disabledNodeAttribute]: this.disabledNodeValues,
  };

  constructor(
    private router: Router,
    private siteService: SiteService,
    private sidenavService: SidenavService,
    private ngZone: NgZone,
    iconRegistry: MatIconRegistry,
    domSanitizer: DomSanitizer,
  ) {
    Object.keys(this.iconDefinitions).forEach((iconName: string) => {
      console.log(iconName);
      iconRegistry.addSvgIcon(
        iconName,
        domSanitizer.bypassSecurityTrustResourceUrl(
          this.iconDefinitions[iconName],
        ),
      );
    });
  }
  ngOnInit(): void {
    const sitesSubscriber = this.siteService
      .getAllSites$()
      .subscribe(sites => {
        this.sites.push(...sites);
        this.treeData = this.sites;
        if (this.sites[0]) {
          this.expandedNodes.add(this.sites[0]);
        }

        // TODO: might break like this, make this selection more dynamic to be the first selectable thing in the list
        if (
          this.sites[0]?.children &&
          this.sites[0].children.length > 0
        ) {
          this.expandedNodes.add(this.sites[0].children[0]);
        }
        if (this.sites[0]?.id) {
          this.selectedNodes.add(this.sites[0]);
        }
        this.loading = false;
      });

    this.subscribers.push(sitesSubscriber);
  }

  ngOnDestroy(): void {
    this.subscribers.forEach(subscriber => subscriber.unsubscribe());
  }

  initClicked(clicked: TreeNode<Site>): void {
    if (!clicked[0]) {
      return;
    }

    this.clicked = clicked[0];
    if (this.clicked['entityType']?.toLowerCase() === 'device') {
      this.onDeviceClicked(this.clicked);
    }
    if (this.clicked['entityType']?.toLowerCase() === 'site') {
      this.onSiteClicked(this.clicked);
    }
  }

  onDeviceClicked(device: TreeNode<Site>): void {
    this.ngZone.run(() => {
      this.router.navigate([`/details/${device.id}/view-device`])
    });
  }

  onSiteClicked(site: TreeNode<Site>): void {
    this.ngZone.run(() => {
      this.router.navigate([`/details/${site.id}/view-site`]);
    });
  }

  expansionHandler(nodes: TreeNode<Site>[]): void {
    this.shownExpandedNodes = nodes;
  }

  selectionHandler(nodes: TreeNode<Site>[]): void {
    if (!nodes[0]) {
      return;
    }

    if (nodes[0]['spaceType'] === this.SITE_SPACE_TYPE) {
      this.onSiteClicked(nodes[0]);
    }

    if (nodes[0]['siteType'] == this.DEVICE_SPACE_TYPE) {
      this.onDeviceClicked(nodes[0]);
    }
  }

  clickedHandler(node: TreeNode<Site>) {
    this.clickedNode = node;
  }
}
