import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router, RouterEvent, Event} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-shared-services';
import { Subscription} from 'rxjs';
import { LoaderService, ResourceService, SidenavService } from '@service';
import { environment } from 'src/environments/environment.prod';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

enum Views {
  geo,
  form,
}

@Component({
  selector: 'premo-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChild('searchInput') input: ElementRef;

  views: typeof Views = Views;
  formModeChecked = true;
  sidenavOpen = true;
  navUrl: string;
  subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private cookieService: CookieService,
    private sidenavService: SidenavService,
    public loaderService: LoaderService,
    private resourceService: ResourceService<string>,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
    this.loaderService.regexEndpoints = ['ess-parameters', 'permission', 'run'];
    this.getNavUrl();
    this.matIconRegistry.addSvgIcon(
      'noData',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/svg-icons/no-data.svg'),
    );
  }

  ngOnInit(): void {
    const { defaults } = environment;
    const locale = this.cookieService.getI18NLocale() || 'en';
    const brand = this.cookieService.getI18NBrand() || defaults.brand.toUpperCase();
    this.translateService.setDefaultLang(`${defaults.locale}.${defaults.brand.toUpperCase()}`);
    let translateSubscription = this.translateService.use(`${locale}.${brand}`).subscribe(() => {
      this.translateService.get('APP.TITLE').subscribe((result) => {
        document.title = result;
      });
    });
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.formModeChecked = !event.url.includes('/geo');
      }
    });

    const closeSubscription = this.sidenavService.close$.subscribe(() => (this.sidenavOpen = false));
    this.subscriptions.push(...[translateSubscription, closeSubscription]);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  ngAfterViewInit(): void {
    const logo = document.querySelector('.logo') as HTMLElement;
    logo.style.cursor = 'pointer';
    logo.addEventListener('click', () => {
      this.router.navigateByUrl('/');
    });

    this.sidenavService.setSidenav(this.sidenav);
  }

  private getNavUrl(): void {
    const resourceServiceSub = this.resourceService.get('links/nav').subscribe((navUrl) => (this.navUrl = navUrl));
    this.subscriptions.push(resourceServiceSub);
  }

  goToFormView() {
    this.router.navigateByUrl('/');
  }

  goToGeoView() {
    this.router.navigateByUrl('/geo')
  }

  toggleGeoView(): void {
    this.formModeChecked ? this.goToFormView() : this.goToGeoView() ;
  }

  isLargeScreen(): boolean {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    return width > 1280;
  }
}
